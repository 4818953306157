'use client'

import { Advantages } from './Advantages/Advantages'
import { Hero } from './Hero/Hero'
import { Plans } from './Plans/Plans'
import { Representative } from './Representative/Representative'
import { Results } from './Results/Results'
import { Solutions } from './Solutions/Solutions'

export { Advantages, Hero, Plans, Representative, Results, Solutions }
