'use client'

import { useState } from 'react'

import { Container, Tag, Title } from '@/components'

import { Solution } from './components'

import { SOLUTIONS } from './contants'

import styles from './styles.module.scss'

export const Solutions = () => {
  const [expandedCardIndex, setExpandedCardIndex] = useState()

  return (
    <section id="solucoes" className={styles.solutions}>
      <Container>
        <Tag bgColor="white">Soluções</Tag>

        <Title
          customClass={styles.solutions__title}
          text="Nossas soluções impactam diretamente  a saúde do seu negócio."
        />

        <div
          className={`${styles.solutions__list} animate-init`}
          data-animate="fade-up"
        >
          {SOLUTIONS.map((solution, index) => (
            <Solution
              {...solution}
              index={index}
              key={`solution-card-${index}`}
              expandedCardIndex={expandedCardIndex}
              setExpandedCardIndex={setExpandedCardIndex}
            />
          ))}
        </div>
      </Container>
    </section>
  )
}
