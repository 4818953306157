'use client'

import dynamic from 'next/dynamic'
import { redirect } from 'next/navigation'

import { Hero } from '@/sections'
import { Banner, BannerButtons, If } from '@/components'

import { useProposalContext } from '@/context/ProposalContext'

import usePageLayout from '@/hooks/useLayout'
import useLazyLoad from '@/hooks/useLazyLoad'

import { Plans } from './sections'

const Paywall = dynamic(() =>
  import('@/components/Paywall/Paywall').then((mod) => mod.Paywall)
)

const Help = dynamic(() =>
  import('@/sections/Help/Help').then((mod) => mod.Help)
)

const Footer = dynamic(() =>
  import('@/components/Footer/Footer').then((mod) => mod.Footer)
)

const Solutions = dynamic(() =>
  import('./sections/Solutions/Solutions').then((mod) => mod.Solutions)
)

const Advantages = dynamic(() =>
  import('./sections/Advantages/Advantages').then((mod) => mod.Advantages)
)

const Results = dynamic(() =>
  import('./sections/Results/Results').then((mod) => mod.Results)
)

const Representative = dynamic(() =>
  import('./sections/Representative/Representative').then(
    (mod) => mod.Representative
  )
)

const redirectToHome = () => {
  const isOrganic = process.env.NEXT_PUBLIC_BUILD_TYPE === 'organic'

  // eslint-disable-next-line no-undef
  if (!isOrganic && environmentId === 'production') {
    redirect('/')
  }
}

export default function Home() {
  const lazyLoad = useLazyLoad(50)
  redirectToHome()
  usePageLayout()

  const { isPaywallOpen } = useProposalContext()

  return (
    <main>
      {isPaywallOpen && <Paywall />}

      <Hero
        variant="organic"
        title="Ajudamos sua empresa a tomar decisões de crédito mais seguras e reduzir o risco de inadimplência."
        subtitle="Não importa o seu modelo de negócio, nós podemos te ajudar"
      />

      <If condition={lazyLoad}>
        <Plans planType="postpaid" />
        <Advantages />
        <Solutions />
        <Plans planType="prepaid" />
        <Representative />
        <Results />
        <Help shouldRenderFilters />
        <Footer hasBannerSpacement={true} />
      </If>
      <Banner
        isFixed
        hasBorder
        hideTextOnMobile
        text="Central de vendas Equifax | BoaVista"
      >
        <BannerButtons />
      </Banner>
    </main>
  )
}
