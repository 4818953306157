'use client'

import { Container, Proposal } from '@/components'

import styles from './styles.module.scss'

export const Hero = () => {
  return (
    <>
      <section className={styles.hero}>
        <Container classname={styles.hero__wrapper}>
          <div className={styles.hero__textContent}>
            <h1 className={styles.hero__title}>
              Ajudamos sua empresa a tomar decisões de crédito mais seguras e
              reduzir o risco de inadimplência.
            </h1>

            <h2 className={styles.hero__subtitle}>
              Não importa o seu modelo de negócio, nós podemos te ajudar
            </h2>
          </div>

          <div className={styles['hero__proposal']}>
            <Proposal />
          </div>
        </Container>
      </section>
    </>
  )
}
