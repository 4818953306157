export const RESULTS = [
  {
    icon: 'equifax-users-plus',
    number: 150,
    measure: 'mil',
    text: 'de usuários satisfeitos.'
  },
  {
    icon: 'equifax-user-business',
    number: 282,
    measure: 'milhões',
    text: 'de CPFs e CNPJs em nosso banco de dados.'
  },
  {
    icon: 'equifax-register',
    number: 141,
    measure: 'bilhões',
    text: 'de ocorrências registradas em base negativa e positiva.'
  },
  {
    icon: 'equifax-file-analysis-2',
    number: 12,
    measure: 'milhões',
    text: 'de consultas de relatórios realizadas por dia.'
  }
]
