import classnames from 'classnames'

import { Icon } from '@/components'

import styles from './Solution.module.scss'

export const Solution = ({
  index,
  icon,
  title,
  text,
  details,
  expandedCardIndex,
  setExpandedCardIndex
}) => {
  const isCurrentSolutionExpanded = expandedCardIndex === index

  const onExpandCard = () => {
    if (isCurrentSolutionExpanded) {
      setExpandedCardIndex(null)
      return
    }

    setExpandedCardIndex(index)
  }

  return (
    <div
      className={classnames(styles.solution, {
        [styles['solution--expanded']]: isCurrentSolutionExpanded
      })}
    >
      <div className={styles.solution__icon}>
        <Icon bgcolor="white" name={icon} />
      </div>

      <p className={styles.solution__title}>{title}</p>
      <p className={styles.solution__text}>{text}</p>

      <button
        id={`solutionCard-expand-button--${index}`}
        onClick={() => onExpandCard()}
      >
        <Icon
          name="arrow-down"
          className={classnames(styles.solution__expandIcon, {
            [styles['solution__expandIcon--up']]: isCurrentSolutionExpanded
          })}
        />
      </button>

      <ul
        className={classnames(styles.solution__details, {
          [styles['solution__details--expanded']]: isCurrentSolutionExpanded
        })}
      >
        {details.map(({ icon, text, url }, index) => (
          <li
            key={`solution-detail-${index}`}
            className={styles.solution__detail}
          >
            <Icon name={icon} color="primary" size="small" />
            <p>
              {text}
              {url && (
                <a
                  href={url}
                  target="_blank"
                  rel="noreferrer"
                  id={`solution-seeMore-link--${index}`}
                  className={styles['solution__detail--link']}
                >
                  Saiba mais
                </a>
              )}
            </p>
          </li>
        ))}
      </ul>
    </div>
  )
}
