import classnames from 'classnames'

import { Container, Icon, Title } from '@/components'

import { ADVANTAGES } from './constants'

import styles from './Advantages.module.scss'

export const Advantages = () => {
  return (
    <section
      className={classnames(styles.advantages, {
        [styles['advantages--animated']]: false
      })}
    >
      <Container>
        <Title
          align="left"
          text="Conheça as principais vantagens de contratar um plano Pós-pago para o seu negócio:"
        />

        <div className={styles.advantages__list}>
          {ADVANTAGES.map(({ icon, text }, index) => (
            <div
              data-animate="fade-up"
              key={`advantage-${index}`}
              className={`${styles.advantages__item} animate-init`}
            >
              <Icon name={icon} size="small" color="primary" />
              <p>{text}</p>
            </div>
          ))}
        </div>
      </Container>
    </section>
  )
}
