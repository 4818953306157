'use client'

import Image from 'next/image'

import { Contact } from '@/sections'

import Illustration from '/public/illustrations/representative.svg'

export const Representative = () => {
  return (
    <Contact
      title="Seja um representante Equifax | BoaVista"
      text="Comercialize nossas soluções e tenha diversos benefícios para o seu negócio."
      image={
        <Image src={Illustration} alt="ilustraçãot" width="307" height="219" />
      }
      ctaProps={{
        variant: 'primary',
        id: 'representative-becomeRepresentative-button',
        url: 'https://materiais.boavistaservicos.com.br/representanteautorizado',
        icon: 'equifax-representative',
        text: 'Quero ser representante'
      }}
    />
  )
}
