export const SOLUTIONS = [
  {
    icon: 'equifax-client-search',
    title: 'Prospecção e Aquisição',
    text: (
      <>
        Ajudamos sua empresa a descobrir novos clientes para ampliar suas
        oportunidades de negócio. <br />
        <br />
      </>
    ),
    details: [
      {
        icon: 'equifax-megaphone',
        text: 'Obtenha segmentações personalizadas para campanhas de marketing.'
      },
      {
        icon: 'equifax-users-plus',
        text: 'Encontre os clientes ideais para o seu negócio.'
      },
      {
        icon: 'equifax-target',
        text: 'Oferte seus produtos e serviços para quem realmente pretende comprar.'
      },
      {
        icon: 'equifax-cube',
        text: 'BlueBox: ',
        url: 'https://www.boavistaservicos.com.br/blue-box/'
      }
    ]
  },
  {
    icon: 'equifax-score-hand',
    title: 'Concessão e Gestão',
    text: (
      <>
        Fornecemos os dados certos e garantimos análises detalhadas para que
        você tome sempre as melhores decisões, de acordo com os seus objetivos
        de negócio.
      </>
    ),
    details: [
      {
        icon: 'equifax-user-analysis',
        text: 'Análise do comportamento financeiro dos seus clientes.'
      },
      {
        icon: 'equifax-graphic-analysis',
        text: 'Avaliação do risco de inadimplência.'
      },
      {
        icon: 'money-coin',
        text: 'Aumento das vendas e, consequentemente, dos lucros.'
      }
    ]
  },
  {
    icon: 'equifax-client-up',
    title: 'Cobrança e Recuperação',
    text: (
      <>
        Conheça o perfil dos seus clientes e entenda quais possuem mais
        capacidade de pagar um débito em aberto.
      </>
    ),
    details: [
      {
        icon: 'sending-mail',
        text: 'Comunicações automatizadas, via e-mail e SMS.'
      },
      {
        icon: 'equifax-graphics',
        text: 'Maior recuperação de dívidas.'
      },
      {
        icon: 'equifax-money-check',
        text: 'Prejuízos cada vez menores.'
      }
    ]
  },
  {
    icon: 'equifax-locked-page',
    title: 'Identificação e Antifraude',
    text: 'Transformamos dados em tomadas de decisão precisas, combatendo a fraude em cada etapa do processo: Onboarding, Transacional e Chargeback',
    details: [
      {
        icon: 'equifax-robot',
        text: 'Inteligência Artificial e comportamento de navegação para criar o modelo perfeito de análise de risco para o seu negócio.'
      },
      {
        icon: 'equifax-shopping-bag',
        text: 'Validação rápida de pedidos nas compras online.'
      },
      {
        icon: 'equifax-shield-2',
        text: 'Proteção máxima para o seu e-commerce. ',
        url: 'https://www.konduto.com/'
      },
      {
        icon: 'equifax-pantheon',
        text: 'Compartilhamento de indícios de fraude (Resolução 6). ',
        url: 'https://www.boavistaservicos.com.br/resolucao-conjunta-6-banco-central/'
      }
    ]
  }
]
