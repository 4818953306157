import { useEffect, useState } from 'react'

export const usePosition = ({
  startRef,
  endRef,
  comparisonLimit = { top: 0, bottom: 0 }
}) => {
  const [isVisibleOnScreen, setIsVisibleOnScreen] = useState(false)

  const handleScroll = () => {
    const { top = 0 } = startRef?.current?.getBoundingClientRect() || {}
    const { bottom = 0 } = endRef?.current?.getBoundingClientRect() || {}

    setIsVisibleOnScreen(
      top < comparisonLimit.top && bottom > comparisonLimit.bottom
    )
  }

  const onScroll = () => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }

  useEffect(() => {
    onScroll()
  }, [])

  return { isVisibleOnScreen }
}
