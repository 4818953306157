import { useState, useEffect } from 'react'

import { Icon } from '@/components'

import styles from './Result.module.scss'

export const Result = ({ icon, number, measure, text, isVisibleOnScreen }) => {
  const [counter, setCounter] = useState(number)

  useEffect(() => {
    if (isVisibleOnScreen) {
      let start = 0

      const incrementTime = (1 / number) * 1000

      let timer = setInterval(() => {
        start += 1

        setCounter(start)

        if (start === number) clearInterval(timer)
      }, incrementTime)
    }
  }, [isVisibleOnScreen])

  return (
    <div className={styles.result}>
      <div className={styles.result__icon}>
        <Icon name={icon} color="white" size="small" />
      </div>

      <p className={styles.result__number}>
        +{counter} {measure}
      </p>

      <p className={styles.result__text}>{text}</p>
    </div>
  )
}
