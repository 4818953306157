import { Icon } from '@/components'

import styles from './PlansHeader.module.scss'

export const PlansHeader = ({ icon, title }) => {
  return (
    <div className={styles.plansHeader}>
      <div className={styles.plansHeader__icon}>
        <Icon color="primary" size="medium" bgcolor="white" name={icon} />
      </div>

      <p className={styles.plansHeader__title}>{title}</p>
    </div>
  )
}
